@font-face {
    font-family: "dhtype";
    /* src: url("../../arca.otf"); */
    /* src: url("../../Ubuntu-Medium.ttf"); */
    src: url("../../Exo2-Regular.ttf");
}


.events-container {
    padding: 10px;
}
.events-container .item {
    margin-bottom: 15px !important;
    background-color: #fff;
    width: 65%;
    margin: 5px auto;
    padding: 5px;
}

.title {
    margin-top: 10px !important;
    font-family: dhtype !important;
    color: #444 !important;
    font-weight: bold !important;
    padding-top: 5px;
    font-size: 30px !important;
}

.event-title {
    font-weight: bold !important;
    font-family: dhtype !important;
    color: #444 !important;
    font-size: large !important;
}
.event-subtitle1 {
    /* font-weight: bold !important; */
    font-family: dhtype !important;
    color: #444 !important;
    font-size: medium !important;
}
.event-subtitle2 {
    /* font-weight: bold !important; */
    font-family: dhtype !important;
    color: #444 !important;
    font-size: small !important;
}
.event-date {
    color: #888 !important;
    font-size: 14px !important;
    /* font-style: italic; */
}
.event-content {
    padding: 10px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
.MuiButtonBase-root {
    font-family: dhtype !important;
}
.event-image {
    padding: 10px;
}
.event-image img {
    /* height: 150px; */
    width: 50%;
    height: auto;
}








/* @media (max-width: 970px) { */
@media (max-width: 800px) {
    .title {
        font-size: 20px !important;
    }
    .event-title {
        font-size: small !important;
    }
    .event-subtitle1 {
        font-size: x-small !important;
    }
    .event-subtitle2, .event-date {
        font-size: x-small !important;
    }
    .event-action-btn {
        font-size: 13px !important;
        z-index: 1;
    }
    .events-container .item {
        width: 97%;
    }
    .event-image img {
        /* height: 150px; */
        width: 90%;
        height: auto;
    }
}
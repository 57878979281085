@font-face {
    font-family: "dhtype";
    /* src: url("../../ltype.ttf"); */
    /* src: url("../../DICOTME.TTF"); */
    /* src: url("../../FRAMDCN.TTF"); */
    /* src: url("../../Ubuntu-Medium.ttf"); */
    src: url("../../Exo2-Bold.ttf");

}
@font-face {
    font-family: "dhtypemedium";
    src: url("../../Exo2-Medium.ttf");
}
@font-face {
    font-family: "dhtypeextrabold";
    src: url("../../Exo2-ExtraBold.ttf");
}

.event-page-container {
    padding: 20px;
    width: 80%;
    max-width: 1200px;
    margin: auto auto;
}










.result-table {
    padding: 10px 10px;
    background-color: #fff;
}
.result-table .title {
    margin-top: 0;
}
.times-tabs {
    margin-top: 13px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.514), 0px 0px 2px 1px rgba(0, 0, 0, 0), 0px 0px 6px 3px rgba(0, 0, 0, 0);
}
.selectFormControl {
    width: 100%;
}
.loader {
    width: 100%;
    margin-top: 10px;
}
.filters {
    background-color: #fff;
    padding: 20px;
    padding-bottom: 1px;
    /* box-shadow: 0px 2px 1px -1px #5a5a5a; */
    border-radius: 4px;
    /* box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.6); */
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.514), 0px 0px 2px 1px rgba(0, 0, 0, 0), 0px 0px 6px 3px rgba(0, 0, 0, 0);
}
.filters .select {
    /* width: 100%; */
    margin-bottom: 20px;
}


.event-data {
    margin-top: 10px;
}
.event-data .img {
    text-align: center;
    /* padding-left: 5%;
    padding-right: 5%; */
}
.event-data .img img {
    width: 50%;
    max-width: 900px;
    height: auto;
}


.title {
    font-size: x-large !important;
    padding: 0px;
}
.event-page-title {
    margin-top: 8px !important;
    font-weight: bold !important;
    font-size: xx-large !important;
    font-family: dhtype !important;
}
.event-page-title2 {
    font-weight: bold !important;
    font-size: x-large !important;
    font-family: dhtype !important;
}
.event-page-title3 {
    font-weight: bold !important;
    font-size: large!important;
    font-style: italic !important;
    font-family: dhtype !important;
}


/* ----------------- */
/* MUI TAB, MUI LIST */
/* ----------------- */
.MuiTab-wrapper {
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: dhtype;
    font-size: 18px;
}
.MuiListSubheader-root {
    font-weight: bold !important;
    color: #3d55aebc !important;
    padding-left: 5px !important;
    font-style: italic !important;
    font-size: large !important;
}
.MuiFormLabel-root {
    font-size: large !important;
    font-weight: bold !important;
}
.MuiInputBase-root {
    font-size: x-large !important;
    font-weight: bold !important;
}

.result-table .stage-name-grid {
    /* padding-top: 5px;
    padding-left: 10px;
    font-size: medium;
    font-weight: bold;
    font-style: italic;
    color: #3d55aeed; */
    font-size: 15px;
    margin: 6px;
    margin-block-start: 0px;
    color: #3d55aedc;
    /* color:#696969; */
    font-weight: bold;
    font-family: dhtypemedium;
    text-align: center;

    white-space: break-spaces;
}



/* ------------- */
/* MUI DATAGRID */
/* ------------- */






/* TIEMPOS PRUEBA Y GENERAL */

/* Root */


/* .MuiDataGrid-root .MuiDataGrid-cell {
    min-height: 70px !important;
    max-height: 70px !important;
} */
/* .result-table .MuiDataGrid-row {
    min-height: 70px !important;
    max-height: 70px !important;
} */



.MuiDataGrid-root .MuiDataGrid-colCell {
    display: flex !important;
    /* font-size: 9px !important; */
    align-items: center !important;
    padding: 0px !important;
    color: #6d6d6de1 !important;   
}
.MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
    justify-content: center !important;
    font-family: dhtypeextrabold;
    /* font-size: 25px !important; */
}
.MuiDataGrid-root .MuiDataGrid-cell {
    font-size: medium !important;
    white-space: pre !important;
    line-height: inherit !important;
    color: #1f1f1f !important;
    /* font-size: 8px !important; */
    font-family: dhtype;
    padding-left: 2px !important;
    padding-right: 2px !important;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.MuiDataGrid-root .MuiDataGrid-cellWithRenderer {
    display: block !important;
}

/* Posicion */
.MuiDataGrid-cell[data-field="pos"]  {
    /* font-size: 10px !important; */
    font-size: large !important;
}

/* Tripulacion */
.MuiDataGrid-cell[data-field="pilotonom"]  {
    display: flex !important;
    text-align: left !important;
    /* padding: 0px !important; */
    justify-content: left !important;
}
.MuiDataGrid-cell[data-field="pilotonom"] .tripulacion--bandera {  
    display: table;
    margin-top: 0px;
    margin-bottom: 0px;
    align-content: center; 
}
.MuiDataGrid-cell[data-field="pilotonom"] .tripulacion--bandera img {  
    height: auto;
    width: 23px;
    float: left;
    clear: both;
    margin-right: 3px;
}
.MuiDataGrid-cell[data-field="pilotonom"] .tripulacion--bandera p {
    /* display: block; */
    margin-block-start: 0px;
    margin-block-end: 0px;

    display: flex !important;
    justify-content: left !important;
    align-items: center !important;
}

/* Auto (coche numero, auto) */
.MuiDataGrid-cell[data-field="pilotonom"] .tripulacion--auto .numero {
    font-family: dhtypeextrabold;
    font-style: normal;
    /* color: #3f51b5; */
}
.MuiDataGrid-cell[data-field="pilotonom"] .tripulacion--auto .auto {
    font-style: italic;
    font-family: dhtypemedium;
    /* color:#181818; */
}
/* Auto (coche numero, auto) */
/* .MuiDataGrid-cell[data-field="auto"] {
    font-style: italic !important;
    text-align: left !important;
    justify-content: left !important;
    display: flex !important;
}
.MuiDataGrid-cell[data-field="auto"] p {
    margin-block-start: 0px;
    margin-block-end: 0px;
}
.MuiDataGrid-cell[data-field="auto"] .numero {
    font-style: normal !important;
    color: #3f51b5;
}
.MuiDataGrid-cell[data-field="auto"] .auto {
    overflow: hidden !important;
    display: -webkit-box !important ;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important; 
    white-space: pre-wrap !important;
    padding-right: 2px !important;
} */

/* Clase */
.MuiDataGrid-cell[data-field="clase"]  {
    /* Multi linea (hasta 3 lineas, luego ...) */
    overflow: hidden !important;
    display: -webkit-box !important ;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 3 !important; 
    white-space: pre-wrap !important;

    display: flex !important;
    text-align: center !important;
}

/* Tiempo (tiempo, penalizacion, neto) */
.MuiDataGrid-cell[data-field="tiempo"], .MuiDataGrid-cell[data-field="acumulado"]  {
    display: flex !important;
    justify-content: left !important;
    text-align: left !important;
    position: relative;
}
.tiempo--pen--neto {
    display: table;
    align-items: center;
    padding-top: 0px !important;
    padding-left: 1px;
}
.tiempo--pen--neto div {
    display: table-row;
    vertical-align: middle;
}
.tiempo--pen--neto .penalizacion {
    /* font-size: 7px; */
    font-size: small;
    font-style: italic;
    color: #c03b3b;
    padding-top: 15px !important;
}
.tiempo--pen--neto .link {
    /* font-size: 9.5px; */
    font-size: large;
    padding-left: 4px;
    color: #3f51b5;
}
/* .tiempo--pen--neto .link .linkIcon {
    font-size: 30px;
    color: #7f7f7f;
    transform: rotate(-60deg) !important;
    height: auto;
    float: right;
    clear: both;
    margin-left: 3px;
} */
.tiempo--pen--neto .touchIcon {
    font-size: x-large;
    color: #b5b5b5;
    position: absolute;
    left: 90px;
    top: 18px;
    transform: rotate(-30deg);
    z-index: 1;
}


/* Diferencia */
.MuiDataGrid-cell[data-field="difprimero"] {
    /* max-width: max-content !important; */

    /* Multi linea (hasta 3 lineas, luego ...) */
    overflow: hidden !important;
    display: -webkit-box !important ;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important; 
    white-space: pre-wrap !important;

    display: flex !important;
    text-align: center !important;

}
.MuiDataGrid-cell[data-field="difprimero"] p {
    margin-block-start: 0px;
    margin-block-end: 0px;
}
.MuiDataGrid-cell[data-field="difprimero"] .difprimero {
    /* font-size: medium !important; */
    font-style: normal !important;
}
.MuiDataGrid-cell[data-field="difprimero"] .difanterior {
    font-style: italic !important;
    font-size: small !important;
}

/* Km/h */
.MuiDataGrid-cell[data-field="kmh"]  {
    /* font-size: medium !important; */
    padding: 0px !important;
}


/* TIEMPOS GENERAL */


/* .MuiDataGrid-colCell[data-field="acumulado"] {
    padding-right: 2px !important;
    padding-left: 10px !important;
}
.MuiDataGrid-cell[data-field="acumulado"]  {
    text-align: center !important;
    padding-left: 0px !important;
    max-width: max-content !important;
    padding-top: 12px !important;
    font-size: 9px !important;
} */


/* SEGUIMIENTO */


/* Auto / Tripulacion */
.tracking-table .MuiDataGrid-cell[data-field="cochenumero"]  {
    display: flex !important;
    justify-content: left !important;
    align-items: center !important;
}
.tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .coche--numero  {
    /* font-size: 10px !important; */
    font-size: large;
    color: #3f51b5;
    margin-right: 2px;
    padding-left: 10px;
    font-family: dhtypeextrabold;
}
.tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .coche--datos  {
    /* font-size: medium; */
    color: #5b5b5b;
    font-style: italic;
    /* font-family: dhtypemedium !important; */
}
.tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .categoria--nombre  {
    /* font-size: medium; */
    font-style: italic;
    padding-left: 10px;
    color: #5b5b5b;
}
.tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .orden--largada  {
    color: #5b5b5b;
    /* font-style: italic; */
    font-family: dhtypemedium !important;
    font-size: 90%;
}
.tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .piloto--nombre, .tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .copiloto--nombre  {
    /* font-size: 10px !important; */
    font-size: large;
    font-family: dhtype;
}
.tracking-table .MuiDataGrid-cell p {
    margin-block-start: 0px;
    margin-block-end: 0px;
}

/* Horarios */
.tracking-table .MuiDataGrid-cell[data-field="horarios"]  {
    justify-content: left !important;
}

/* Info */
.tracking-table .MuiDataGrid-cell[data-field="info"]  {
    justify-content: left !important;
    white-space: break-spaces !important;
    /* font-family: dhtypemedium !important; */
}

/* Posiciones */
.tracking-table .MuiDataGrid-cell[data-field="posiciones"]  {
    justify-content: left !important;
}




/* ----------- */
/* TIMES MODAL */
/* ----------- */
.times-modal-content {
    position: absolute;
    top: 20%;
    left: 20%;
    background-color: #3f51b5;
    width: 700px;
    padding: 5px;
    height: 500px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.254), 0px 5px 8px 0px rgba(0, 0, 0, 0.856), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
}
.modal-table {
    width: 700px;
    height: 380px;
    background-color: #fff;
}
.modal-header {
    padding-bottom: 10px;
    padding-left: 10px;
    white-space: pre;
}
.modal-header p {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 2px;
    font-size: large;
    color: #fff;
}
.modal-close span {
    display: inline-block;
    padding: 4px 10px;
    color: #fff;
    background-color: firebrick;
    cursor: pointer;
    width: auto;
    font-size: 20px;
    border-radius: 5px;
}
.modal-close {
    text-align: right;
    height: auto;
}

.modal-grid  {
    line-height: 12px !important ;
}

.modal-grid .MuiDataGrid-cell {
    font-size: medium !important;

    /* line-height: inherit !important; */
    display: flex !important;
}
.modal-grid .MuiDataGrid-cell[data-field="prueba"]  {
    justify-content: left !important;
    padding-left: 5px !important;
}
.modal-grid .MuiDataGrid-cell[data-field="tiempo"]  {
    justify-content: center !important;
}
.modal-grid .MuiDataGrid-cell[data-field="penalizacion"] p {
    margin-block-start: 0px;
    margin-block-end: 0px;
}
.modal-grid .MuiDataGrid-cell[data-field="penalizacion"] .penalizacion  {
    font-size: small !important;
    font-style: italic !important;
    color: #c03b3b !important;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.modal-grid .MuiDataGrid-cell[data-field="penalizacion"] .penalizacioninfo  {
    font-size: small !important;
    font-style: italic !important;
    font-family: dhtypemedium !important;

    /* Multi linea (hasta 3 lineas, luego ...) */
    /* overflow: hidden !important; */
    display: -webkit-box !important ;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important; 
    white-space: pre-wrap !important;
}
.modal-grid .MuiDataGrid-cell[data-field="neto"]  {
    color: #3f51b5 !important;
}




















/* ----------- */
/* ----------- */
/* RESPONSIVE  */
/* ----------- */
/* ----------- */







/* @media (max-width: 970px) { */
@media (max-width: 970px) {
    .event-page-container {
        padding: 0px;
        width: 98%;
        margin: 10px auto;
    }
}

@media (max-width: 768px) {





    .result-table {
        padding: 5px 2px;
    }
    .result-table .title {
        margin-top: 0;
    }
    .selectFormControl {
        width: 100%;
    }
    .filters .select {
        margin-bottom: 10px;
    }
    .event-data .img img {
        width: 80%;
        height: auto;
    }
    .title {
        font-size: large !important;
    }
    .event-page-title {
        font-size: small !important;
    }
    .event-page-title2 {
        font-size: x-small !important;
    }
    .event-page-title3 {
        font-size: x-small !important;
    }
    
    
    
    /* ----------------- */
    /* MUI TAB, MUI LIST */
    /* ----------------- */
    .MuiTab-wrapper {
        font-size: 10px;
    }
    .MuiListSubheader-root {
        font-size: x-small !important;
    }
    .MuiFormLabel-root {
        font-size: small !important;
    }
    .MuiInputBase-root {
        font-size: small !important;
    }
    .MuiButtonBase-root {
        font-size: small !important;
    }
    .result-table .stage-name-grid {
        font-size: 8px;
    }





    
    /* ------------- */
    /* MUI DATAGRID */
    /* ------------- */
    /* TIEMPOS PRUEBA Y GENERAL */
    /* Root */

    .MuiDataGrid-root .MuiDataGrid-colCell {
        font-size: 9px !important;
    }
    /* .MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
        justify-content: center !important;
    } */
    .MuiDataGrid-root .MuiDataGrid-cell {
        font-size: 9px !important;
        /* min-height: 35px !important;
        max-height: 35px !important; */
       
    }
    /* .result-table .MuiDataGrid-row {
        min-height: 35px !important;
        max-height: 35px !important;
    } */

    /* Posicion */
    .MuiDataGrid-colCell[data-field="pos"]  {
        min-width: 25px !important;
        max-width: 25px !important;
    }
    .MuiDataGrid-cell[data-field="pos"]  {
        font-size: 10px !important;
        min-width: 25px !important;
        max-width: 25px !important;
    }

    /* Tripulacion */
    .MuiDataGrid-colCell[data-field="pilotonom"]  {
        min-width: 140px !important;
        max-width: 140px !important;
    }
    .MuiDataGrid-cell[data-field="pilotonom"]  {
        min-width: 140px !important;
        max-width: 140px !important;
    }
    .MuiDataGrid-cell[data-field="pilotonom"] .tripulacion--bandera img {  
        width: 12px;
    }
    .MuiDataGrid-cell[data-field="pilotonom"] .tripulacion--bandera p {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    .MuiDataGrid-cell[data-field="pilotonom"] .tripulacion--auto .auto {
        color: #5b5b5b;
        font-weight: bold;
    }


    /* Auto (coche numero, auto) */
    /* .MuiDataGrid-colCell[data-field="auto"] {
        min-width: 65px !important;
        max-width: 65px !important;
    }
    .MuiDataGrid-cell[data-field="auto"] {
        min-width: 65px !important;
        max-width: 65px !important;
    }
    .MuiDataGrid-cell[data-field="auto"] .numero {
        font-size: 9px !important;
    } */

    /* Clase */
    .MuiDataGrid-colCell[data-field="clase"]  {
        min-width: 60px !important;
        max-width: 60px !important;
    }
    .MuiDataGrid-cell[data-field="clase"]  {
        min-width: 60px !important;
        max-width: 60px !important;
    }

    /* Tiempo (tiempo, penalizacion, neto) */
    .MuiDataGrid-colCell[data-field="tiempo"], .MuiDataGrid-colCell[data-field="acumulado"]  {
        min-width: 65px !important;
        max-width: 65px !important;
    }
    .MuiDataGrid-cell[data-field="tiempo"], .MuiDataGrid-cell[data-field="acumulado"]  {
        min-width: 65px !important;
        max-width: 65px !important;
    }
    /* .tiempo--pen--neto {
        display: table;
        align-items: center;
        padding-top: 0px !important;
        padding-left: 1px;
    } */
    /* .tiempo--pen--neto div {
        display: table-row;
        vertical-align: middle;
    } */
    .tiempo--pen--neto .penalizacion {
        font-size: 8px;
    }
    .tiempo--pen--neto .link {
        font-size: 9.5px;
    }
    .tiempo--pen--neto .link .netoIcon {
        font-size: 15px;
        /* font-size: x-small; */
        /* padding-left: 4px; */
        color: #da2121;
    }
    /* .tiempo--pen--neto .link .linkIcon {
        font-size: 14px;
    } */
    .tiempo--pen--neto .touchIcon {
        font-size: small;

        left: 51px;
        top: 10px;
    }

    /* Diferencia */
    .MuiDataGrid-colCell[data-field="difprimero"] {
        min-width: 65px !important;
        max-width: 65px !important;
        content: "DIF. 1ro/Ant" !important;
    }
    .MuiDataGrid-cell[data-field="difprimero"] {
        min-width: 65px !important;
        max-width: 65px !important;
    }
    .MuiDataGrid-cell[data-field="difprimero"] p {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
    .MuiDataGrid-cell[data-field="difprimero"] .difprimero {
        font-size: 9px !important;
    }
    .MuiDataGrid-cell[data-field="difprimero"] .difanterior {
        font-size: 8px !important;
    }

    /* Km/h */
    .MuiDataGrid-colCell[data-field="kmh"]  {
        min-width: 50px !important;
        max-width: 50px !important;
    }
    .MuiDataGrid-cell[data-field="kmh"]  {
        min-width: 50px !important;
        max-width: 50px !important;
    }

    /* TIEMPOS GENERAL */


    /* .MuiDataGrid-colCell[data-field="acumulado"] {
        padding-right: 2px !important;
        padding-left: 10px !important;
    }
    .MuiDataGrid-cell[data-field="acumulado"]  {
        text-align: center !important;
        padding-left: 0px !important;
        max-width: max-content !important;
        padding-top: 12px !important;
        font-size: 9px !important;
    } */


    /* SEGUIMIENTO */
    /* .tracking-table .MuiDataGrid-row {
        min-height: 55px !important;
        max-height: 55px !important;
    } */
    /* .tracking-table .MuiDataGrid-cell {
        min-height: 55px !important;
        max-height: 55px !important;
    } */

    /* Auto / Tripulacion */
    .tracking-table .MuiDataGrid-colCell[data-field="cochenumero"]  {
        min-width: 140px !important;
        max-width: 140px !important;
    }
    .tracking-table .MuiDataGrid-cell[data-field="cochenumero"]  {
        min-width: 140px !important;
        max-width: 140px !important;
    }
    .tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .coche--numero  {
        font-size: 10px !important;
        padding-left: 5px;
    }
    .tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .coche--datos  {
        font-size: 8px !important;
    }
    .tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .categoria--nombre  {
        font-size: 8px;
        padding-left: 5px;
    }
    .tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .orden--largada  {
        font-size: 8px;
    }
    .tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .piloto--nombre, .tracking-table .MuiDataGrid-cell[data-field="cochenumero"] .copiloto--nombre  {
        font-size: 9px !important;
    }

    /* Horarios */
    .tracking-table .MuiDataGrid-colCell[data-field="horarios"]  {
        min-width: 75px !important;
        max-width: 75px !important;
    }
    .tracking-table .MuiDataGrid-cell[data-field="horarios"]  {
        min-width: 75px !important;
        max-width: 75px !important;
    }
    /* Info */
    .tracking-table .MuiDataGrid-colCell[data-field="info"]  {
        min-width: 110px !important;
        max-width: 110px !important;
    }
    .tracking-table .MuiDataGrid-cell[data-field="info"]  {
        min-width: 110px !important;
        max-width: 110px !important;
    }
    /* Posiciones */
    .tracking-table .MuiDataGrid-colCell[data-field="posiciones"]  {
        min-width: 200px !important;
        max-width: 200px !important;
    }
    .tracking-table .MuiDataGrid-cell[data-field="posiciones"]  {
        min-width: 200px !important;
        max-width: 200px !important;
    }









        
    /* ----------- */
    /* TIMES MODAL */
    /* ----------- */
    .times-modal-content {
        top: 18%;
        left: 1%;
        width: 96%;
        padding: 3px;
        height: 400px;
    }
    .modal-table {
        width: 100%;
        height: 300px;
    }
    .modal-header p {
        font-size: small;
    }
    .modal-close span {
        padding: 2px 10px;
    }
    .modal-grid .MuiDataGrid-cell  {
        font-size: x-small !important;
        min-height: 30px !important;
        max-height: 30px !important;
    }
    .modal-grid .MuiDataGrid-colCell[data-field="prueba"]  {
        min-width: 200px !important;
        max-width: 200px !important;
    }
    .modal-grid .MuiDataGrid-cell[data-field="prueba"]  {
        min-width: 200px !important;
        max-width: 200px !important;
    }
    .modal-grid .MuiDataGrid-colCell[data-field="tiempo"]  {
        min-width: 65px !important;
        max-width: 65px !important;
    }
    .modal-grid .MuiDataGrid-cell[data-field="tiempo"]  {
        min-width: 65px !important;
        max-width: 65px !important;
    }
    .modal-grid .MuiDataGrid-colCell[data-field="penalizacion"]  {
        min-width: 110px !important;
        max-width: 110px !important;
    }
    .modal-grid .MuiDataGrid-cell[data-field="penalizacion"]  {
        min-width: 110px !important;
        max-width: 110px !important;
    }
    .modal-grid  {
        line-height: 9px !important ;
    }
    .modal-grid .MuiDataGrid-cell[data-field="penalizacion"] .penalizacion  {
        font-size: xx-small !important;
    }
    .modal-grid .MuiDataGrid-cell[data-field="penalizacion"] .penalizacioninfo  {
        font-size: xx-small !important;
    }
    .modal-grid .MuiDataGrid-colCell[data-field="neto"]  {
        min-width: 75px !important;
        max-width: 75px !important;
    }
    .modal-grid .MuiDataGrid-cell[data-field="neto"]  {
        min-width: 75px !important;
        max-width: 75px !important;
    }
        
    


}
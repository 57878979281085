.footer-container {
    padding: 10px;
    text-align: center;
}

.footer-text, .contact-link {
    display: inline-block;
}

.footer-text {
    padding-bottom: 30px;
}

.contact-link {
    position: relative;
    top: 6px;
    left: 5px;
}
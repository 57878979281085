@font-face {
    font-family: "dhtype";
    /* src: url("../../ltype.ttf"); */
    /* src: url("../../DICOTME.TTF"); */
    /* src: url("../../FRAMDCN.TTF"); */
    /* src: url("../../Ubuntu-Medium.ttf"); */
    src: url("../../Exo2-Bold.ttf");

}
@font-face {
    font-family: "dhtypemedium";
    src: url("../../Exo2-Medium.ttf");
}
@font-face {
    font-family: "dhtypeextrabold";
    src: url("../../Exo2-ExtraBold.ttf");
}

.live-event-page-container {
    padding: 0px;
    width: 784px;
    align-content: center;
    max-width: 1200px;
    margin: 0px 0px;
}




.live-stage-times-table {
    align-content: center;
    width: 784px;
    
  }

  .live-stage-times-table .MuiDataGrid-cell {
    background-color: #000000; 
    color: #ffffff !important; 
    border: 1px solid #444444; 
    font-size: xx-large !important;
    font-family: dhtypemedium;
  }

  .MuiDataGrid-root .MuiDataGrid-cell {
    /* background-color: #000000;  */
    /* color: #ffffff !important;  */
    /* border: 1px solid #444444 !important; 
    font-size: xx-large !important;
    font-family: dhtypemedium; */
}





  
  .live-stage-times-table .MuiDataGrid-cell {
    border-color: #ffffff !important; /* Color del borde de las celdas */
  }
  
  
  .live-stage-times-table .MuiDataGrid-columnSeparator {
    visibility: hidden !important; /* Ocultar separadores de columna si es necesario */
  }
  
  .live-stage-times-table .MuiDataGrid-footerContainer {
    background-color: #e0e0e0; /* Color de fondo del pie de página */
  }





/* DIFERENCIA */

.live-time-difprimero {
    background-color: #f33232 !important;
    width: 140px;
}


/* ----------------- */
/* TITULO */
/* ----------------- */
  .live-title-grid {
    /* padding-top: 5px;
    padding-left: 10px;
    font-size: medium;
    font-weight: bold;
    font-style: italic;
    color: #3d55aeed; */

    font-size: xx-large;
    margin: 3px;
    margin-block-start: 0px;
    /* color: #3d55aef7; */
    color:#ffffff;

    /* font-weight: bold; */
    font-family: dhtypemedium;
    text-align: center;

    white-space: break-spaces;
}







.live-result-table {
    padding: 0px 0px;
    background-color: #000000;
}
.live-result-table .title {
    margin-top: 0;
}







/* ------------- */
/* MUI DATAGRID */
/* ------------- */


.container {
    flex-grow: 1;
    /* flex-wrap: wrap; */
    position: relative;
    /* z-index: -1; */


    /* padding-top: 83px;   ---- SE USA CON BANNER DE AUSPICIANTES */
    
}

.menu-button {
    margin-right: 2px;
}

.title {
    flex-grow: 1
}
.logo {
    margin: 0 auto;
    padding-right: 50px;
}
.logo img {
    width: 150px;
    height: auto;
    display: flex;
}
.homeIcon {
    font-size: 30px !important;
}





/* @media (min-width: 464px) {
    .container {
        padding-top: 83px;
    }
}
@media (min-width: 768px) {
    .container {
        padding-top: 92px;
    }
    .homeIcon {
        font-size: 35px !important;
    }
}
@media (min-width: 970px) {
    .container {
        padding-top: 140px;
    }
}
@media (min-width: 1700px) {
    .container {
        padding-top: 173px;
    }
    .homeIcon {
        font-size: 35px !important;
    }
} */








